<template>
  <ul class="flex flex-col">
    <li class="text-black text-xl font-semibold p-2 mt-2">
      Allgemeine Themen
    </li>
    <li class="flex flex-col w-full mb-2 rounded border card">
      <a
        @click.prevent="
          $emit(
            'current',
            current === `${$options.name}` ? null : `${$options.name}`
          )
        "
        href=""
        class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold card-header"
        >Themen</a
      >
      <div
        v-if="printing || current === $options.name"
        class="flex flex-col w-full p-2"
      >
        <div class="flex self-end space-x-2">
          <button
            class="rounded-sm px-2 py-1 bg-blue-600 hover:bg-blue-800 text-white save-btn-small"
            @click.prevent="addTheme"
          >
            Thema hinzufügen
          </button>
        </div>

        <div
          class="p-2"
          v-for="(row, index) in $v.data.$each.$iter"
          :key="`data-${index}`"
        >
          <div class="flex justify-end mb-2">
            <button
              class="rounded-sm w-6 h-6 bg-red-600 text-white flex justify-center items-center"
              @click.prevent="data.splice(index, 1)"
              v-if="!data[index].disabled"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <vue-editor
            v-model="row.comment.$model"
            :disabled="data[index].disabled"
            :class="{ 'border-red-600': row.comment.$error }"
            :editorToolbar="[
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [
                { align: 'left' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
            ]"
          />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

const tempTheme = {
  comment: '',
}

export default {
  name: 'review-generaltopic',
  props: [
    'rows',
    'current',
    'scales',
    'isAssesment',
    'who',
    'marks',
    'categories',
  ],
  data() {
    return {
      data: [],
    }
  },
  validations: {
    data: {
      $each: {
        comment: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init(
      //this.data = this.rows.length ? [...this.rows] : [{ ...tempTheme }]

    )
  },
  methods: {
    init() {
      if(this.rows)this.data.push(...this.rows)
      else this.data = [{ ...tempTheme }]
    },
    addTheme() {
      this.data.push({
        ...tempTheme,
      })
    },
  },
  watch: {
    data: {
      handler: function(n) {
        this.$emit('error', {
          key: 'generaltopic',
          value: this.$v.$invalid,
          error: 'Bitte geben Sie ein oder mehrere Themen an',
        })
        this.$emit('update', { key: 'generaltopic', value: n })
      },
      deep: true,
    },
  },
}
</script>

<style></style>
